<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-row justify="end" class="pr-2">
      <v-col cols="auto" class="pr-1">
        <app-btn @click="dialogItem = true">
          <v-icon>mdi-plus-thick</v-icon> Add Item
        </app-btn>
      </v-col>
      <!-- <v-col cols="auto" class="pr-1">
        <app-btn @click="dialogZone = true">
          <v-icon>mdi-plus-thick</v-icon> Add Zone
        </app-btn>
      </v-col> -->
      <v-col cols="auto" class="pr-1">
        <app-btn @click="openStockTake()">
          <v-icon>mdi-plus-thick</v-icon> Add New StockTake
        </app-btn>
      </v-col>
    </v-row>

    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Stock Takes"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="stockTakes"
          :search.sync="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isDescending"
          :footer-props="{
            showFirstLastPage: true,
          }"
          single-expand
          :expanded.sync="expanded"
          item-key="stocktakeId"
          show-expand
        >
          <template v-slot:top>
            <v-dialog v-model="dialogStockTake" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formStockTake }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="StockTakeForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedStockTake.description"
                            label="Description"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeStockTake">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogZone" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formZone }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="StockTakeForm">
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedZoneItem.zoneId"
                            :items="zones"
                            item-text="zoneName"
                            item-value="zoneId"
                            label="Zone"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeZone">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="saveZone">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogItem" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="StockTakeForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.ean" label="Ean" />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.locationId"
                            :items="materialLocations"
                            item-text="location.locationName"
                            item-value="location.locationId"
                            label="Location"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="dialogItemStockTake"
                            :items="stockTakes"
                            item-text="description"
                            item-value="stocktakeId"
                            label="Stock Take"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.stocktakeZoneId"
                            :items="stocktakeZones"
                            item-text="zone.zoneName"
                            item-value="stocktakeZoneId"
                            label="Stock Take Zone"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.quantity"
                            label="Quantity"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeItem">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div>{{ formatTime(item.createdAt) }}</div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="primary pa-1">
              <v-data-table
                :headers="sub_sub_headers"
                :items="item.materialsStockTakeZones"
                :footer-props="{
                  showFirstLastPage: true,
                }"
                single-expand
                :expanded.sync="expandedItems"
                item-key="stocktakeZoneId"
                show-expand
              >
                <template v-slot:[`header.actions`]>
                  <app-btn @click="openZone(item)">
                    <v-icon>mdi-plus-thick</v-icon> Add Zone
                  </app-btn>
                </template>
                <template v-slot:[`item.zoneId`]="{ item }">
                  <div>{{ getZone(item.zoneId) }}</div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="accent pa-1">
                    <v-data-table
                      :headers="sub_headers"
                      :items="item.materialsStockTakeZones"
                      :footer-props="{
                        showFirstLastPage: true,
                      }"
                    >
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import * as moment from "moment/moment";

export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialogStockTake: false,
    dialogZone: false,
    dialogItem: false,
    dialogDelete: false,
    headers: [
      {
        text: "Stock Take",
        align: "start",
        value: "description",
      },
      { text: "Created At", value: "createdAt" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    sub_headers: [
      {
        text: "Zone",
        align: "start",
        value: "zoneId",
      },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    sub_sub_headers: [
      {
        text: "Zone",
        align: "start",
        value: "zoneId",
      },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    sortBy: "stocktakeId",
    isDescending: true,
    stockTakes: [],
    expanded: [],
    expandedItems: [],
    zones: [],
    stocktakeZones: [],
    locations: [],
    materialLocations: [],
    materials: [],
    dialogItemStockTake: 0,
    editedStockTakeIndex: -1,
    editedZoneIndex: -1,
    editedIndex: -1,
    editedItem: {
      serialNumber: "",
      locationId: 0,
      stocktakeZoneId: 0,
    },
    defaultItem: {
      ean: "",
      locationId: 0,
      stocktakeZoneId: 0,
      quantity: "",
    },
    editedZoneItem: {
      ean: "",
      locationId: 0,
      stocktakeZoneId: 0,
      quantity: "",
    },
    defaultZoneItem: {
      stocktakeId: 0,
      zoneId: 0,
    },
    editedStockTake: {
      description: "",
    },
    defaultStockTake: {
      description: "",
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Item" : "Edit Item";
    },
    formZone() {
      return this.editedZoneIndex === -1 ? "Add Zone" : "Edit Zone";
    },
    formStockTake() {
      return this.editedStockTakeIndex === -1
        ? "Add New StockTake"
        : "Edit StockTake";
    },
    checkEditedItemEan() {
      return this.editedItem.ean;
    },
  },

  watch: {
    dialogStockTake(val) {
      val || this.closeStockTake();
    },
    dialogItem(val) {
      val || this.closeItem();
    },
    dialogZone(val) {
      val || this.closeZone();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogItemStockTake(val) {
      this.getStockTakeZones(val);
    },
    checkEditedItemEan: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          let item = this.materials.find((x) => x.ean === val);
          if (item) {
            this.materialLocations = item.materialLocations;
            console.log(this.materialLocations);
          }
        }
      },
      deep: true,
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("/MaterialsStockTake/GetAllWithZone").then((response) => {
        this.stockTakes = response.data;
      });
      this.$http.get("/Zone").then((response) => {
        this.zones = response.data;
      });
      this.$http.get("/Location").then((response) => {
        this.locations = response.data;
      });
      this.$http.get("/Material/GetAllWithLocations").then((response) => {
        this.materials = response.data.data;
      });
    },
    openStockTake() {
      this.dialogStockTake = true;
      this.editedStockTake.description = moment().format(
        "MMMM Do YYYY, h:mm:ss a"
      );
    },
    editItem(item) {
      this.editedIndex = this.stockTakes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogStockTake = true;
    },
    deleteItem(item) {
      this.editedIndex = this.stockTakes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let materialIndex = this.editedIndex;
      this.$http
        .get("/MaterialStockStockTake/Delete", {
          params: {
            id: this.editedItem.materialStockStockTakeId,
          },
        })
        .then((response) => {
          if (response.data.isSuccess) {
            this.stockTakes.splice(materialIndex, 1);
            this.$toast.success("StockTake deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(
              "Something went wrong while deleting the stockTake.",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        });
      this.closeDelete();
    },
    closeStockTake() {
      this.dialogStockTake = false;
      this.$nextTick(() => {
        this.editedStockTake = Object.assign({}, this.defaultStockTake);
        this.editedStockTakeIndex = -1;
      });
    },
    openZone(item) {
      this.editedZoneItem.stocktakeId = item.stocktakeId;
      this.dialogZone = true;
    },
    closeZone() {
      this.dialogZone = false;
      this.$nextTick(() => {
        this.editedZoneItem = Object.assign({}, this.defaultZoneItem);
        this.editedZoneIndex = -1;
      });
    },
    closeItem() {
      this.dialogItem = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.$refs.StockTakeForm.validate()) {
        let materialIndex = this.editedIndex;
        if (materialIndex > -1) {
          // this.$http
          //   .patch("/MaterialStockStockTake/Update", this.editedItem)
          //   .then((response) => {
          //     if (response.data.success) {
          //       this.stockTakes.splice(materialIndex, 1, response.data.data);
          //       this.$toast.success(
          //         "StockTake Updated successfully.",
          //         "Success",
          //         {
          //           position: "topRight",
          //         }
          //       );
          //     } else {
          //       this.$toast.error(
          //         "Something went wrong while updating the stockTake.",
          //         "Error",
          //         {
          //           position: "topRight",
          //         }
          //       );
          //     }
          //   });
        } else {
          this.$http
            .post("/MaterialsStockTake/Create", this.editedStockTake)
            .then((response) => {
              if (response.data.isSuccess) {
                this.stockTakes.unshift(response.data.outputObject);
                this.$toast.success(
                  "StockTake added successfully.",
                  "Success",
                  {
                    position: "topRight",
                  }
                );
              } else {
                this.$toast.error(
                  "Something went wrong while adding stockTake.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        }
        this.closeStockTake();
      }
    },
    saveZone() {
      if (this.$refs.StockTakeForm.validate()) {
        let zoneIndex = this.editedZoneIndex;
        if (zoneIndex > -1) {
          // this.$http
          //   .post("/MaterialsStockTake/AddZone", this.editedZoneItem)
          //   .then((response) => {
          //     if (response.data.success) {
          //       this.stockTakes.splice(zoneIndex, 1, response.data.data);
          //       this.$toast.success(
          //         "StockTake Updated successfully.",
          //         "Success",
          //         {
          //           position: "topRight",
          //         }
          //       );
          //     } else {
          //       this.$toast.error(
          //         "Something went wrong while updating the stockTake.",
          //         "Error",
          //         {
          //           position: "topRight",
          //         }
          //       );
          //     }
          //   });
        } else {
          this.$http
            .post("/MaterialsStockTake/AddZone", this.editedZoneItem)
            .then((response) => {
              if (response.data.isSuccess) {
                let output = response.data.outputObject;
                let index = this.stockTakes.findIndex(
                  (x) => x.stocktakeId === output.stocktakeId
                );
                this.stockTakes[index].materialsStockTakeZones.push(output);
                this.$toast.success(
                  "Zone added to the stock take successfully.",
                  "Success",
                  {
                    position: "topRight",
                  }
                );
              } else {
                this.$toast.error(
                  "Something went wrong while adding the zone.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        }
        this.closeZone();
      }
    },
    formatTime(dateTime) {
      return moment(dateTime).format("MMMM Do YYYY, h:mm:ss a");
    },
    getZone(id) {
      let zone = this.zones.find((x) => x.zoneId === id);
      if (zone) {
        return zone.zoneName;
      }
      return "";
    },
    getStockTakeZones(id) {
      if (id) {
        let stocktake = this.stockTakes.find((x) => x.stocktakeId === id);
        if (stocktake) {
          this.stocktakeZones = stocktake.materialsStockTakeZones;
        }
      }
    },
  },
};
</script>
